import { Card, Grid, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import ServiceInForm from "../../Form/ServiceIn";

export const ServiceIn = () => {
  const { t } = useTranslation();
  return (
    <>
      <Typography variant='h2' sx={{ fontWeight: 700 }}>
        {t("ADM Service Registration")}
      </Typography>
      <Grid component={Card} sx={{ padding: 6, overflow: "auto" }} maxWidth='sm'>
        <ServiceInForm />
      </Grid>
    </>
  );
};
