import { Backdrop, Button, CircularProgress, Stack } from "@material-ui/core";
import FieldCheckbox from "components/Input/Checkbox";
import FieldDate from "components/Input/Date";
import FieldSelect from "components/Input/Select";
import FieldText from "components/Input/Text";
import { useWialon } from "context/useWialon";
import { endOfToday } from "date-fns/esm";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

const SERVICE_REPORTS = [
	{ label: "GENERAL_SERVICE", value: "GENERAL_SERVICE" },
	{ label: "TYRE_REPLACEMENT", value: "TYRE_REPLACEMENT" },
	{ label: "ACCIDENT", value: "ACCIDENT" },
	{ label: "SPARE_PARTS", value: "SPARE_PARTS" },
	{ label: "DEEP_CLEANING", value: "DEEP_CLEANING" },
];

const ServiceInForm = () => {
	const { t } = useTranslation();
	const search = useLocation().search;
	const user = new URLSearchParams(search).get("user") || "";
	const methods = useForm({
		defaultValues: {
			vehicle: NaN,
			type: "",
			service_mileage: NaN,
			service_mileage_out: NaN,
			tyre_count: NaN,
			tyre_year: "",
			replacment_vehicle: null,
			date: new Date(),
			description: "",
			performedBy: user,
		},
	});
	const { wialon } = useWialon();
	// const { setLoading, loading } = usePage();
	const [loading, setLoading] = useState(true);
	const [mounted, setMounted] = useState(false);
	const [selectedVehicle, setSelectedVehicle] = useState<number | null>(null);
	const [selectedService, setSelectedService] = useState<string | null>(null);
	const [odometers, setOdometers] = useState<{ id: number; cnm: number }[]>([]);
	const [units, setUnits] = useState<
		{
			label: string;
			value: string;
			resourceName?: string;
			resourceId?: string;
			model?: string;
		}[]
	>([]);

	const watch = methods.watch;
	const watchType = watch("type", "");

	const getUnitsInService = async () => {
		if (wialon) {
			setLoading(true);
			const list = await wialon.getUnitsInService();
			const odometers = await wialon.getvehiclesOdometer();
			setOdometers(odometers);
			setLoading(false);
			setUnits(list);
		}
	};

	const executeReport = async (props: {
		vehicle: number;
		type: string;
		service_mileage: number;
		service_mileage_out: number;
		tyre_count: number;
		tyre_year: string;
		date: Date;
		description?: string;
	}) => {
		const { vehicle, type, service_mileage, date } = props;
		console.log(props);
		if (vehicle && type && service_mileage && date) {
			setLoading(true);
			await wialon?.setService(props);
			setLoading(false);
		} else {
			toast.error("Error Invalid Input Detected");
		}
	};

	const isAccidentOrSpare = ["SPARE_PARTS", "ACCIDENT"].includes(watchType);
	const wialonMileage = odometers.find((o) => o.id === selectedVehicle)?.cnm;

	useEffect(() => {
		if (!mounted) {
			methods.setValue("date", endOfToday());
			setMounted(true);
		}
	}, [mounted]);
	useEffect(() => {
		getUnitsInService();
	}, [wialon]);

	return (
		<FormProvider {...methods}>
			<Stack spacing={1}>
				<Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
					<CircularProgress color="inherit" />
				</Backdrop>
				<FieldSelect
					name="vehicle"
					label={t("Vehicle")}
					options={units}
					rules={{ required: String(t("required_field")) }}
					onChange={(v) => {
						setSelectedVehicle(v);
					}}
				/>
				<FieldSelect
					name="type"
					label={t("Service Type")}
					options={SERVICE_REPORTS}
					rules={{ required: String(t("required_field")) }}
					onChange={(v) => {
						setSelectedService(v);
						if (v !== "TYRE_REPLACEMENT") {
							methods.setValue("tyre_count", NaN);
							methods.setValue("tyre_year", "");
						}
					}}
				/>
				{selectedService === "TYRE_REPLACEMENT" && (
					<FieldText
						name="tyre_count"
						label={t("Tyre Count")}
						type="number"
						rules={{ required: String(t("required_field")) }}
					/>
				)}
				{selectedService === "TYRE_REPLACEMENT" && (
					<FieldText
						name="tyre_year"
						label={t("Tyre Year")}
						placeholder="WW/YY"
						// type="number"
						rules={{ required: String(t("required_field")), pattern: /[0-5][0-9]\/\d{2}/ }}
					/>
				)}
				<FieldText
					name="description"
					label={t("Description")}
					rules={isAccidentOrSpare ? { required: String(t("required_field")) } : undefined}
				/>
				<FieldText name="location" label={t("Workshop")} rules={{ required: String(t("required_field")) }} />
				<FieldText
					name="service_mileage"
					label={t("Service Mileage In")}
					type="number"
					rules={{ required: String(t("required_field")) }}
					// inputProps={{ onChange: (e) => { setMilage(Number(e.target.value)), methods.setValue("service_mileage_out", Number(e.target.value)) } }}
				/>
				<p style={{ marginTop: 15 }}>
					{t("Wialon mileage counter for the selected vehicle is")} {selectedVehicle ? wialonMileage + t(" km") : "-"}
				</p>
				<p style={{ marginTop: 10 }}>- {t("Please overwrite Wialon mileage counter if the difference is large")}</p>
				{/* {milage && wialonMileage && ((wialonMileage - milage) < -100 || (wialonMileage - milage) > 100) && <Alert severity="warning">This is a warning alert — check it out!</Alert>} */}
				<FieldCheckbox
					label={t("overwrite Wialon counter with your input")}
					name="odometer_overwrite"
					disabled={selectedVehicle === null}
					// checked={checked}
					// onChange={handleChange}
					inputProps={{ "aria-label": "primary checkbox" }}
				/>
				<FieldSelect
					name="replacment_vehicle"
					label={t("replacment_vehicle")}
					options={units}
					// rules={{ required: String(t("required_field")) }}
				/>
				<FieldDate name="date" label={t("date_in")} rules={{ required: String(t("required_field")) }} />
				<Button variant="contained" onClick={methods.handleSubmit(executeReport)} disabled={loading}>
					{t("generate")}
				</Button>
			</Stack>
		</FormProvider>
	);
};

export default ServiceInForm;
