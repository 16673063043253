import { List } from "@material-ui/core";
import ArtTrackIcon from "@material-ui/icons/ArtTrack";
import AssessmentIcon from "@material-ui/icons/Assessment";
import ReportIcon from "@material-ui/icons/Report";
import { FC } from "react";
import { useLocation } from "react-router-dom";
import { LinkedItem, LinkedItemProps } from "../Link/Drawer";
import { NestedLinkedItem } from "../Link/Drawer/Nested";

const DrawerComponents: FC = () => {
	const search = useLocation().search;
	const user = new URLSearchParams(search).get("user");
	const route = [
		{
			title: "Reports",
			path: `/reports`,
			icon: <AssessmentIcon fontSize="large" />,
			isAllowed: true,
		},
		{
			title: "Services",
			route: [
				{
					path: `/service-in`,
					icon: <ArtTrackIcon fontSize="large" />,
					title: "Service In",
					isAllowed: ["atsuae", "ats.adm.osama", "ats.jasem.alsalama", "ats.adm.nitin", 'ats.adm.abdulla.hassan'].includes(user || ""),
				},
				{
					path: `/service-out`,
					icon: <ArtTrackIcon fontSize="large" />,
					title: "Service Out",
					isAllowed: ["atsuae", "ats.adm.osama", "ats.jasem.alsalama", "ats.adm.nitin", 'ats.adm.abdulla.hassan'].includes(user || ""),
				},
				{
					path: `/service-tables`,
					icon: <ArtTrackIcon fontSize="large" />,
					title: "Details",
					isAllowed: ["atsuae", "ats.adm.osama", "ats.jasem.alsalama", "ats.adm.nitin", 'ats.adm.abdulla.hassan'].includes(user || ""),
				},
			],
			isAllowed: ["atsuae", "ats.adm.osama", "ats.jasem.alsalama", "ats.adm.nitin", 'ats.adm.abdulla.hassan'].includes(user || ""),
			isNested: true,
			icon: <ReportIcon fontSize="large" />,
		},
		{
			title: "Drivers",
			route: [
				{
					path: `/driver-tables`,
					icon: <ArtTrackIcon fontSize="large" />,
					title: "Details",
					isAllowed: ["atsuae"].includes(user || ""),
				},
			],
			isAllowed: ["atsuae"].includes(user || ""),
			isNested: true,
			icon: <ArtTrackIcon fontSize="large" />,
		},
		{
			title: "Workshop",
			route: [
				{
					path: `/workshop-almasoud`,
					icon: <ArtTrackIcon fontSize="large" />,
					title: "Registration",
					isAllowed: ["atsuae"].includes(user || ""),
				},
			],
			isAllowed: ["atsuae"].includes(user || ""),
			isNested: true,
			icon: <ReportIcon fontSize="large" />,
		},
	];

	return (
		<List>
			{route.map((item, index) => {
				if (item.route && item.isAllowed && item.isNested) {
					return <NestedLinkedItem {...item} key={index} />;
				} else if (item.isAllowed) {
					return <LinkedItem {...item} key={index} />;
				}
				return null;
			})}
		</List>
	);
};

export interface DrawerComponentsProps {
	handleLogout?: () => void;
	route: LinkedItemProps[];
}

export default DrawerComponents;
export interface DrawerComponentsProps {
	role?: string;
	handleLogout?: () => void;
	route: LinkedItemProps[];
	isAllowed: boolean;
}
