import { FormControl, FormHelperText, FormLabel, TextFieldProps } from "@material-ui/core";
import { InputProps } from "components/Input/types";
import _, { isObject } from "lodash";
import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import ReactSelect, { components } from "react-select";


export interface FieldSelectProps extends InputProps {
    type?: string,
    autoComplete?: string
    inputProps?: TextFieldProps
    options: { label: string, value: string }[]
    isClearable?: boolean
    isId?: boolean
    alternateOption?: {
        labelName: string
        valueName: string
    }
    onChange?: (value: any) => void
}

const FieldSelect: FC<FieldSelectProps> = ({
    label,
    name,
    disabled,
    placeholder,
    rules,
    options,
    defaultValue,
    isId,
    alternateOption,
    loading,
    onChange
}) => {
    const { control, setValue } = useFormContext();
    const getOptionValue = alternateOption ? (item: Record<string, string>) => item[alternateOption.valueName] : undefined
    const getOptionLabel = alternateOption ? (item: Record<string, string>) => item[alternateOption.labelName] : undefined
    const isIdDefaultValue = isId && isObject(defaultValue)
        ? defaultValue?.id
        : defaultValue
    return (
        <Controller
            control={control}
            name={name}
            render={({
                field,
                fieldState: { invalid, error }
            }) => {
                if (isId && defaultValue && defaultValue == field.value) {
                    setValue(name, { id: field.value })
                }
                return (<ReactSelect
                    {...field}
                    components={{
                        Control: (props) => <FormControl fullWidth margin="normal">
                            <FormLabel>{label || _.startCase(_.toLower(name))}</FormLabel>
                            {<components.Control {...props} />}
                            {invalid &&
                                <FormHelperText error>
                                    {error?.message}
                                </FormHelperText>
                            }
                        </FormControl>
                    }}
                    value={options.find(c => {
                        const fieldValue = isId ? field.value.id : field.value
                        const isMatch = c.value == fieldValue
                        return isMatch
                    }) || null}
                    onChange={(data) => {
                        const isObjectValue = isId ? { id: data?.value } : data?.value
                        onChange && onChange(data?.value)
                        return field.onChange(isObjectValue)
                    }}
                    disabled={disabled}
                    placeholder={placeholder}
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    options={options}
                    menuPortalTarget={document.body}
                    isSearchable
                    getOptionValue={getOptionValue}
                    getOptionLabel={getOptionLabel}
                    isLoading={loading}
                />)
            }}
            defaultValue={isIdDefaultValue || ""}
            rules={rules}
        />
    );
};

export default FieldSelect