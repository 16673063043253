import {
  isRejectedWithValue,
  Middleware,
  MiddlewareAPI
} from "@reduxjs/toolkit";
import { toast } from "react-toastify";


// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const errorHandleMiddleware: Middleware = (_api: MiddlewareAPI) => (
  next
) => (action) => {
  if (isRejectedWithValue(action)) {
    console.warn("We got a rejected action!");
    const error = action?.payload?.data?.message;
    try {
      if (error) {
        if (Array.isArray(error)) {
          error.forEach(err => {
            const constraints = Object.values<string>(err.constraints)
            if (constraints) {
              constraints.forEach((constraint) => toast.warn(constraint))
            }
          })
        } else {
          toast.warn(error);
        }
      }
    } catch {
      console.warn("Error showing notification")
    }
  }

  return next(action);
};
