import { Route } from "react-router-dom";
import { ServiceDetails } from "./Details";
import { ServiceIn } from "./ServiceIn";
import { ServiceOut } from "./ServiceOut";

export const DisplayService = ({ user }: { user: string | null }) => {
	return (
		<>
			{user && ["atsuae", "ats.adm.osama", "ats.jasem.alsalama", "ats.adm.nitin", 'ats.adm.abdulla.hassan'].includes(user) && (
				<Route component={ServiceIn} path="/service-in" strict={true} />
			)}
			{user && ["atsuae", "ats.adm.osama", "ats.jasem.alsalama", "ats.adm.nitin", 'ats.adm.abdulla.hassan'].includes(user) && (
				<Route component={ServiceOut} path="/service-out" strict={true} />
			)}
			{user && ["atsuae", "ats.adm.osama", "ats.jasem.alsalama", "ats.adm.nitin", 'ats.adm.abdulla.hassan'].includes(user) && (
				<Route component={ServiceDetails} path="/service-tables" strict={true} />
			)}
		</>
	);
};
