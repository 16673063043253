import { Route } from "react-router";
import { DriverDetails } from "./Details";

const DisplayDrivers = ({ user }: { user: string | null }) => {
  return (
    <>
      {user && ["atsuae"].includes(user) && (
        <Route component={DriverDetails} path='/driver-tables' strict={true} />
      )}
    </>
  );
};

export default DisplayDrivers;
