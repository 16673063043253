import { Checkbox, CheckboxProps, FormControlLabel, Typography } from "@material-ui/core";
import { InputProps } from "components/Input/types";
import _ from "lodash";
import { FC } from "react";
import { Controller } from "react-hook-form";

export interface FieldCheckboxProps extends InputProps {
   type?: string,
   autoComplete?: string
   inputProps?: CheckboxProps
}

const FieldCheckbox: FC<FieldCheckboxProps> = ({
   label,
   name,
   disabled,
   placeholder,
   rules,
   defaultValue,
   control,
   inputProps
}) => {

   const options: CheckboxProps = {
      name: label || _.startCase(_.toLower(name)),
      disabled: disabled,
      placeholder: placeholder,
      color: "primary",
      ...inputProps
   }

   return (
      <Controller
         control={control}
         name={name}
         render={({
            field,
         }) => {
            return (
               // <>
               //    <Typography variant="body2" gutterBottom>{label}</Typography>
               //    <Checkbox
               //       {...field}
               //       {...options}
               //    />
               // </>
               <FormControlLabel
                  value={label}
                  control={<Checkbox
                     {...field}
                     {...options}
                  />}
                  label={label}
                  labelPlacement="end"
               />
            );
         }}
         defaultValue={defaultValue || false}
         rules={rules}
      />
   );
};

export default FieldCheckbox