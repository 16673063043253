import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "app/store";

export interface PageDto {
  tableTitle: string;
}

const initialState: PageDto = {
  tableTitle: "",
};

export const pageSlice = createSlice({
  name: "pages",
  initialState,
  reducers: {
  },
});

// Exportable Actions
// export const { handleDrawer } = pageSlice.actions;

// Commonly Used Data
export const pageSelector = ({ pages }: RootState) => ({
  tableTitle: pages.tableTitle,
});

export default pageSlice.reducer;
