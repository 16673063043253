import { Backdrop, Button, CircularProgress, Stack } from "@material-ui/core";
import FieldDate from "components/Input/Date";
import FieldSelect from "components/Input/Select";
import { usePage } from "context/usePage";
import { useWialon } from "context/useWialon";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const ServiceOutForm = () => {
  const { t } = useTranslation();
  const methods = useForm({
    defaultValues: {
      vehicle: NaN,
      date_out: new Date(),
    }
  });
  const { wialon } = useWialon();
  // const { setLoading, loading } = usePage();
  const [loading, setLoading] = useState(true)
  // const [mounted, setMounted] = useState(false);
  const [units, setUnits] = useState<
    {
      label: string;
      value: string;
      resourceName?: string;
      resourceId?: string;
      model?: string;
    }[]
  >([]);

  const getUnitsOutService = async () => {
    if (wialon) {
      setLoading(true);
      const list = await wialon.getUnitsOutService();
      setLoading(false);
      setUnits(list);
    }
  };

  const executeReport = async (props: {
    vehicle: number;
    date_out: Date;
  }) => {
    const { vehicle, date_out } = props;
    if (vehicle && date_out) {
      setLoading(true);
      await wialon?.setServiceOut(props);
      setLoading(false);
    } else {
      toast.error("Error Invalid Input Detected");
    }
  };

  useEffect(() => {
    getUnitsOutService();
  }, [wialon]);

  return (
    <FormProvider {...methods}>
      <Stack spacing={1}>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <FieldSelect
          name='vehicle'
          label={t("Vehicle")}
          options={units}
          rules={{ required: String(t("required_field")) }}
        />
        <FieldDate
          name='date_out'
          rules={{ required: String(t("required_field")) }}
        />
        <Button
          variant='contained'
          onClick={methods.handleSubmit(executeReport)}
          disabled={loading}
        >
          {t("generate")}
        </Button>
      </Stack>
    </FormProvider>
  );
};

export default ServiceOutForm;
