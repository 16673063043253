import { Stack } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { FC } from "react";
import { Route, useLocation } from "react-router-dom";
import { NavigationPanel } from "../Panel";
import DisplayDrivers from "./Drivers";
import { DisplayReports } from "./Reports";
import { DisplayService } from "./Service";
import DisplayWorkshop from "./Workshop";

const Display: FC = () => {
  const search = useLocation().search;
  const user = new URLSearchParams(search).get("user");
  return (
    <>
      <Grid item display={{ xs: "none", md: "block" }} padding={6} width={350}>
        <NavigationPanel />
      </Grid>
      <Grid
        item
        sm
        sx={{
          flexDirection: "column",
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
          padding: 6,
        }}
        component={Stack}
      >
        <Route component={DisplayReports} path='/reports' strict={true} />
        <DisplayService user={user} />
        <DisplayDrivers user={user} />
        <DisplayWorkshop user={user} />
      </Grid>
    </>
  );
};

export default Display;
