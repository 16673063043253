import { EntityState } from "@reduxjs/toolkit";
import { api } from "app";
import { DriverDto } from "./dto/driver.dto";

const URL = "drivers";

const driverServices = api.injectEndpoints({
  endpoints: (build) => ({
    getDrivers: build.query<EntityState<DriverDto>, void>({
      query: () => ({ url: `${URL}`, method: "GET" }),
      providesTags: [{ type: "Drivers" }],
    }),
    getDriverById: build.query<DriverDto, number>({
      query: (id) => ({ url: `${URL}/${id}`, method: "GET" }),
      providesTags: (_result, _error, id) => [{ type: "Drivers", id: id }],
    }),
    editDriver: build.mutation({
      query: ({ id, ...data }: DriverDto) => ({
        url: `${URL}/${id}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: [{ type: "Drivers" }],
    }),
    getDriverBindingss: build.query<EntityState<DriverDto>, void>({
      query: () => ({ url: `${URL}/latest/xlsx` }),
      providesTags: [{ type: "Drivers" }],
    }),
  }),
  overrideExisting: false,
});

export default driverServices;

export const {
  useGetDriversQuery,
  useEditDriverMutation,
  useGetDriverByIdQuery,
} = driverServices;

export type DriverActionTypes = "createDriver" | "editDriver";
