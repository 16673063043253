import { ApisauceInstance, create } from "apisauce";
import axios from "axios";
import { FormattedReportTable } from "context/usePage";
import { getUnixTime } from "date-fns";
import { isArray } from "lodash";
import { toast } from "react-toastify";
import { ExportExcelProps, exportToExcel } from "utility/exportToExcel";
import { ReportAddons } from "utility/types";
export class WialonApi {
	sid: string;
	interval?: NodeJS.Timer;
	api: ApisauceInstance;
	requestCount: number;
	addCurrentData?: {
		addonField: { label: string; value: keyof ReportAddons }[];
		additionalColumns: AdditionalColumnProps[];
		flags: number;
	};

	constructor(sid: string) {
		this.sid = sid;
		this.api = create({
			baseURL: process.env.REACT_APP_API_URL,
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
		});
		this.api.addRequestTransform((request) => {
			request.headers["Authorization"] = `Bearer ${this.sid}`;
		});
		this.requestCount = 0;
	}

	refreshSession = async () => {
		return axios
			.post(
				"https://hst-api.wialon.com/avl_evts",
				{},
				{
					headers: {
						Accept: "*/*",
						"Content-Type": "application/x-www-form-urlencoded",
					},
					params: { sid: this.sid },
				}
			)
			.then((res) => {
				return res.data?.error && res.data?.error == 1 && res.data.error;
			});
	};

	autoRefresh = () => {
		this.interval = setInterval(async () => {
			const error = await this.refreshSession();
			if (error) {
				console.log(error);
			}
		}, 60000);
	};

	// eslint-disable-next-line @typescript-eslint/ban-types
	sendData = async <ApiResponse = {}>(url: string, params = {}, method: "post" | "get" | "patch" = "post") => {
		return this.api[method]<ApiResponse>(url, params)
			.then((item) => item.data)
			.catch((error) => {
				console.error(error);
				toast.error("Not Authenticated, please Refresh Browser");
			});
	};

	static init = (sid: string) => {
		const wialon = new WialonApi(sid);
		wialon.autoRefresh();
		return wialon;
	};

	getAssignedDriver = async (dateRequested: number, lang = "en") => {
		const list = (
			await this.sendData<{ data: ExportExcelProps }>(
				"/drivers/latest/xlsx",
				{
					dateRequested,
					lang,
				},
				"get"
			)
		)?.data;

		if (list) {
			await exportToExcel(list);
		}
		return list;
	};

	getService = async (date: number) => {
		const list = (await this.sendData<{ data: ExportExcelProps }>("/vehicle-services/report-overview-excel", { date }))
			?.data;

		if (list) {
			await exportToExcel(list);
		}
		return list;
	};

	getServiceWithoutDriver = async (date: number) => {
		const list = (
			await this.sendData<{ data: ExportExcelProps }>("/vehicle-services/report-overview-wo-excel", { date })
		)?.data;

		if (list) {
			await exportToExcel(list);
		}
		return list;
	};

	getServiceHistory = async (props: { fromDate: number; toDate: number }) => {
		const list = (await this.sendData<{ data: ExportExcelProps }>("/vehicle-services/report-overview-excel", props))
			?.data;

		if (list) {
			await exportToExcel(list);
		}
		return list;
	};

	setService = async (props: {
		vehicle: number;
		type: string;
		service_mileage: number;
		service_mileage_out: number;
		date: Date;
		description?: string;
	}) => {
		// const isAccidentOrSpare = ["SPARE_PARTS", "ACCIDENT"].includes(props.type);
		// const interval = isAccidentOrSpare ? 0 : 10000;
		// | { error: { message: string, code: string } }
		const list = await this.sendData<{ data: Record<string, any>[] }>("/vehicle-services", {
			...props,
			date: getUnixTime(props.date),
		});
		console.log("before if else", list);
		if (list?.data) {
			toast.success("Service successfully added");
			return;
		} else {
			console.log(list);
			if ((list as any).error.code === "RESOURCE_DUPLICATE") {
				toast.error("service already added");
			} else {
				toast.error("Something went wrong");
			}
			return;
		}
	};

	setServiceOut = async (props: { vehicle: number; date_out: Date }) => {
		const list = await this.sendData<{ data: Record<string, any>[] }>(
			"/vehicle-services/service-out",
			{
				...props,
				date_out: getUnixTime(props.date_out),
			},
			"patch"
		);
		if (list?.data) {
			toast.success("Service successfully added");
			return;
		} else {
			toast.error("Something went wrong \n" + (list as any).error.message);
		}
	};

	getvehiclesOdometer = async () => {
		const list = await this.sendData<{ id: number; cnm: number }[]>("/vehicles/odometer", {}, "get");
		// const formattedList: {
		// 	value: string;
		// 	label: string;
		// }[] = [];
		// if (list) {
		// 	list.forEach((item) => {
		// 		formattedList.push({
		// 			value: item.id,
		// 			label: `${item.plate}-${item.category}-${item.model}`,
		// 			...item,
		// 		});
		// 	});
		// }
		// return isArray(list) ? formattedList : [];
		return list || [];
	};

	getUnitsInService = async () => {
		const list = (await this.sendData<{ data: Record<string, string>[] }>("/vehicles", {}, "get"))?.data;
		const formattedList: {
			value: string;
			label: string;
		}[] = [];
		if (list) {
			list.forEach((item) => {
				// console.log(item);
				// if (!(item.inService as unknown as boolean) === true)
				formattedList.push({
					value: item.id,
					label: `${item.plate}-${item.category}-${item.model}`,
					...item,
				});
			});
		}
		return isArray(list) ? formattedList : [];
	};

	getUnitsOutService = async () => {
		const list = (await this.sendData<{ data: Record<string, string>[] }>("/vehicles", {}, "get"))?.data;
		const formattedList: {
			value: string;
			label: string;
		}[] = [];
		if (list) {
			list.forEach((item) => {
				// console.log(item);
				if ((item.inService as unknown as boolean) === true)
					formattedList.push({
						value: item.id,
						label: `${item.plate}-${item.category}-${item.model}`,
						...item,
					});
			});
		}
		return isArray(list) ? formattedList : [];
	};

	getTemplates = async () => {
		const list = await this.sendData("/report/templates", {}, "get");
		return isArray(list) ? list.map((item) => ({ value: item.id, label: item.n, ...item })) : [];
	};

	getTemplateObjectIds = async (params: { resourceId: string | number; templateId: number[] }) => {
		const list = await this.sendData("/report/details", params);
		return isArray(list)
			? list.map((item: { id: string; nm: string; u?: number[] }) => ({
					value: item.id,
					label: item.nm,
					count: item?.u?.length || 0,
			  }))
			: [];
	};

	executeReport = async (
		params: {
			objectId: number;
			templateId: number;
			resourceId: number | string;
			interval: {
				from: number;
				to: number;
				flags: number;
			};
		},
		addCurrentData?: AddCurrentDataProps
	) => {
		this.addCurrentData = addCurrentData;
		const status = (await this.sendData<{ remoteExec: number }>("/report/execute", params))?.remoteExec;
		this.requestCount = 0;
		if (status) {
			await this.getReportStatus();
		}
		toast.error("Report Execution Failed");
		return;
	};

	getReportStatus = async (): Promise<FormattedReportTable | void> => {
		this.requestCount++;

		const reportStatus = (
			await this.sendData<{ status: number }>("/report/evaluate", {
				requestCount: this.requestCount,
			})
		)?.status;
		if (reportStatus) {
			if (reportStatus == 4) {
				return this.getReportResult();
			} else if (reportStatus < 4) {
				return await this.getReportStatus();
			} else {
				toast.error("Error:" + reportStatus);
				return;
			}
		}
	};

	getReportResult = async () => {
		const report = await this.sendData<FormattedReportTable>("/report/retrieve", {
			addCurrentData: this.addCurrentData?.flags,
			additionalColumns: this.addCurrentData?.additionalColumns,
		});
		console.log(report);
		if (report) {
			const { data, columns } = report;
			const isValid = data && columns;
			if (isValid) return report;
			toast.error("No Data found on the given Timeframe");
			return;
		}
		toast.error("Error: Retrieving Report");
		return;
	};
}

export interface AddCurrentDataProps {
	addonField: { label: string; value: keyof ReportAddons }[];
	additionalColumns: AdditionalColumnProps[];
	flags: number;
}

export type AdditionalColumnProps = "current_odometer" | "service_interval" | "upcoming_maintenance";
