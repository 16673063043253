import { Button, Container, Typography } from "@material-ui/core";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import img from "resources/img/404.png";

const Page404: FC = () => {
    const { t } = useTranslation();
    return <Container sx={{
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        display: "flex"
    }}>
        <img
            src={img}
            srcSet={img}
            alt={"404"}
            loading="lazy"
            height={450}
        />
        <Typography
            variant="h1"
            sx={{
                marginTop: 5,
                fontSize: `calc(1.3rem + .6vw)`,
                fontFamily: "Poppins,Helvetica,sans-serif",
                fontWeight: 500,
                color: "#A3A3C7"
            }}
        >
            {t("required_login")}
        </Typography>
        <Typography
            variant="h1"
            sx={{
                marginBottom: 5,
                fontSize: `calc(1.3rem + .6vw)`,
                fontFamily: "Poppins,Helvetica,sans-serif",
                fontWeight: 500,
                color: "#A3A3C7"
            }}
        >
            {t("required_login_return")}
        </Typography>
        <Button variant="contained" color="info" size="large" href="http://wialon.atsuae.net">Go Back</Button>
    </Container>
};

export default Page404;