export function selectMapper<Entity extends Record<string, any> | string[]>(entity: Entity, options: SelectMapperOptions): SelectOptions[]
export function selectMapper<Entity extends string[]>(entity: Entity, options?: SelectMapperOptions): SelectOptions[]
export function selectMapper<Entity extends Record<string, any> | string[]>(entity: Entity, options?: SelectMapperOptions): SelectOptions[] {
    return Object.values(entity).reduce<SelectOptions[]>((acc, item) => {
        const key = Object.keys(item)
        if (typeof item === "string") {
            acc.push({ label: item, value: item })
        } else if (options) {
            const value: string = options.value
                ? item[options.value]
                : item.id
            const label: string = options?.label
                ? typeof options?.label === "string"
                    ? item[options.label]
                    : options.label(item)
                : item.name || item[key[0]]
            if (value && label) {
                acc.push({ label, value })
            }
        }
        return acc
    }, [])
}

interface SelectMapperOptions {
    label: string | ((item: string) => string)
    value: string
}

export interface SelectOptions {
    label: string;
    value: string;
}
