import { useMemo } from "react";
import { entitySelector } from "utility/entitySelectors";
import { ServiceDto } from "../dto/service.dto";
import { useGetServicesQuery } from "../vehicleServiceServices";

export const useServices = () => {
  const { data, error, isFetching: isLoading, refetch } = useGetServicesQuery();

  const selectors = entitySelector<ServiceDto>(data);

  return useMemo(() => {
    return {
      data,
      error,
      isLoading,
      refetch,
      ...selectors,
    };
  }, [data, error, isLoading, refetch, selectors]);
};
