import {
  createEntityAdapter,
  isFulfilled,
  Middleware,
  MiddlewareAPI
} from "@reduxjs/toolkit";
import { toast } from "react-toastify";

export const responseUnwrapperMiddleware: Middleware = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _api: MiddlewareAPI
) => (next) => (action) => {
  if (isFulfilled(action)) {
    const hasMessage = action?.payload?.message;
    if (hasMessage) {
      toast.info(hasMessage);
    }

    const data = action?.payload?.data;
    if (data && Array.isArray(data)) {
      action.payload = baseAdapter.addMany(
        baseAdapter.getInitialState(),
        data
      );
    } else if (data) {
      action.payload = data
    }
  }

  return next(action);
};

const baseAdapter = createEntityAdapter({});
