import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { api } from "app";
import pageSlice from "app/slices/pageSlice";
import { errorHandleMiddleware } from "./middlewares/errorHandler";
import { responseUnwrapperMiddleware } from "./middlewares/responseUnwrapper";

const useStore = () => {
  const store = configureStore({
    reducer: {
      pages: pageSlice,
      [api.reducerPath]: api.reducer,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat([
        api.middleware,
        errorHandleMiddleware,
        responseUnwrapperMiddleware,
      ]),
  });

  setupListeners(store.dispatch);
  return store;
};

export const store = useStore();

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
